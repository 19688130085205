import keyConceptsImage from './assets/images/key-concepts.png';

import defaultCompanyIcon from './assets/images/defaultCompanyIcon.png';
import neonIcon from './assets/images/neonIcon.png';

import Header from './components/Header/Header';
import Company from './components/Company/Company';
import CompanyDetail from './components/Company/CompanyDetail';
import { useState } from 'react';
//import AccountDetail from './components/Account/AccountDetail';

const data = [
  /* Company > Account */
  {
    id: 'c-1234',
    label: 'neon',
    icon: neonIcon,
    contacts: [
      {
        type: 'TEL',
        icon: '☎',
        value: '+41 (0) 43 508 03 19',
      },
      {
        type: 'INTERNET',
        icon: '🌎',
        value: 'https://www.neon-free.ch/',
      },
    ],
    faq: 'https://www.neon-free.ch/de/faq/',
    accounts: [
      {
        id: 'a-1234',
        label: 'neon-free',
        type: null,
        questions: [
          {
            id: 'q-8754',
            question: 'Meine Karte ist kaputt/verloren/gestohlen. Was jetzt?',
            answer: 'Sperre deine Karte selber in der App unter «Profil», «Deine Karte» und «Karte sperren». Oder mit der Karten-Notfallnummer: +41 (0) 43 508 03 19.',
          },
          {
            id: 'q-8753',
            question: 'Mein Handy ist kaputt/verloren/gestohlen. Was jetzt?',
            answer: 'Du bist mit deinem Handy schwimmen gegangen und der Trick mit Reis hat nicht funktioniert? Neues Handy kaufen, neon-App herunterladen, Handy für neon und dein Konto aktivieren (bestelle einen neuen Geräteaktivierungscode unter «Profil», «Sicherheit» und «Aktivierungscode generieren»), weiterbanken. Dank den separaten Login-Code und Überweisungs-PIN sollte niemand an deine Daten oder dein Geld kommen. Wenn du willst, kannst du dein Konto auch temporär sperren, schreib uns dazu eine kurze Nachricht. Bitte schicke uns keine sensitiven Daten (Transaktionen, Kontostand, etc.) per E-Mail.',
          },
        ],
      },
      {
        id: 'a-2345',
        label: 'neon-green',
        type: null,
        questions: [
          {
            id: 'q-8755',
            question: '?',
            answer: '!',
          },
        ],
      },
      {
        id: 'a-3456',
        label: 'neon-metal',
        type: null,
      },
    ],
  },
  {
    id: 'c-2345',
    label: 'default',
    icon: defaultCompanyIcon,
  },
  {
    id: 'c-3456',
    label: 'twint',
  },
];

function App() {
  const [selectedCompany, setSelectedCompany] = useState();

  const selectCompanyHandler = (id) => {
    const filteredData = data.filter(company => company.id === id);
    if (filteredData && filteredData.length === 1)
      setSelectedCompany(filteredData[0]);
    else
      setSelectedCompany(null);
  };

  return (
    <div>
      {/*<Header src={keyConceptsImage} />*/}
      <ul id="companies">
        {
          data.map(company => <Company key={company.id} company={company} onCompanySelect={selectCompanyHandler} />)
        }
      </ul>
      <CompanyDetail company={selectedCompany} />
    </div>
  );
}

export default App;