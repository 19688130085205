import Account from "../Account/Account";

const CompanyDetail = (props) => {
    if (!props.company)
        return;

    return (
        <>
            <h1>{props.company.label}</h1>
            <ul id="company-details">
                {
                    props.company.contacts &&
                    <li>
                        <p>{`Kontakt: ${props.company.contacts
                            .map(contact => {
                                return `${contact.icon} ${contact.value}`;
                            })
                            }`}</p>
                    </li>
                }
                {
                    props.company.faq &&
                    <li>
                        <p>{`FAQs: ${props.company.faq}`}</p>
                    </li>
                }
            </ul>
            <ul id="company-accounts">
                {
                    props.company.accounts && props.company.accounts.map(account => <Account key={account.id} account={account} />)
                }
            </ul>
        </>
    );
};

export default CompanyDetail;