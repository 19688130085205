const Account = (props) => {
    if (!props.account.questions)
        return (
            <li>
                <h1 title={props.account.id}>{props.account.label}</h1>
            </li>
        );

    return (
        <li>
            <h1 title={props.account.id}>{props.account.label}</h1>
            <ul id="account-questions">
                {
                    props.account.questions.map((qs) => {
                        return (
                            <li key={qs.id}>
                                <h3 title={qs.id}>{qs.question}</h3>
                                <p>{qs.answer}</p>
                            </li>
                        )
                    })
                }
            </ul>
        </li>
    )
};

export default Account;