import classes from './Company.module.css';

const Company = (props) => {

    const clickHandler = (event) => {
        const companyEl = event.target.closest('li');

        if (!companyEl)
            return;

        props.onCompanySelect(companyEl.dataset.id);
    }

    return (
        <li key={props.id} className={classes.company} onClick={clickHandler} data-id={props.company.id}>
            <img src={props.company.icon} alt={props.company.label} />
            <h2>{props.company.label}</h2>
            <p>{props.company.id}</p>
        </li>
    );
};

export default Company;